<template>
  <div>
     <div class="tip-box" >
      <div style="font-size:22px;text-align: center;">提示</div>
      <div style="font-size:20px;text-align: center; margin-top:5px">请使用手机微信浏览器打开页面</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.tip-box {
  width: 100%;
  position: absolute;
  top: 15%;
}
.v-application--wrap div {
  line-height: 1;
}
</style>